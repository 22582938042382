<template>
    <v-dialog persistent v-model="dialog" max-width="500">
        <v-card>
            <v-card-title><span class="title secondary--text">Select organization</span></v-card-title>

            <v-card-text><v-select class="mt-5" v-model="selectedOrg" :items="sortedOrgNames" outlined></v-select></v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="closeDialog" text>close</v-btn>
                <v-btn @click="fetchOrgSpaces" color="primary" text>confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { sortArray } from '@/utils'
export default {
    data() {
        return {
            dialog: false,
            selectedOrg: ''
        }
    },
    methods: {
        fetchOrgSpaces: function () {
            const org = this.userOrgs.find(org => org.long_id === this.selectedOrg)
            if (this.selectedOrg !== this.currentOrganizationName) {
                this.$router.push({ name: 'home-dashboard', params: { oid: org.oid } })
            }
            this.dialog = false
        },
        closeDialog: function () {
            this.dialog = false
        }
    },
    computed: {
        ...mapState(['userOrgs', 'openOrgSelectorDialog']),
        ...mapState(['userMetadata']),
        ...mapGetters('orgStore', ['orgLongName']),
        sortedOrgNames() {
            const orgNames = sortArray(this.userOrgs, 'long_id', 'ascending', true)
            return orgNames.map(org => org.long_id)
        },
        currentOrganizationName() {
            if (this.$route && this.$route.params.oid !== undefined) {
                return this.orgLongName
            } else {
                return ''
            }
        },
        currentLatestOrg() {
            if (this.userMetadata.latestOrg) {
                return this.userMetadata.latestOrg
            }
            return null
        }
    },
    watch: {
        currentOrganizationName: function (nextVal) {
            if (nextVal) {
                this.selectedOrg = nextVal
            }
        }
    },
    mounted() {
        this.selectedOrg = this.currentOrganizationName
        if (this.openOrgSelectorDialog) {
            this.dialog = true
            this.$store.dispatch('setOpenOrgSelectorDialog', false)
        }
    }
}
</script>
